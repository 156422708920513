import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@rainbow-me+rainbowkit@2.2.0_@tanstack+react-query@5.59.20_@types+react@18.3.12_react-dom@18._nsc4sbf7foxvoxocw67gujqyfi/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/base.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@radix-ui+themes@2.1.0-rc.10_@types+react-dom@18.3.1_@types+react@18.3.12_react-dom@18.3.1_react@18.3.1/node_modules/@radix-ui/themes/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers.tsx");
