import {
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";

import { getDefaultConfig } from "@rainbow-me/rainbowkit";

import { mainnet, base, bsc, arbitrum, polygon, Chain } from "wagmi/chains";
import React, { ReactNode } from "react";
import {  WagmiConfig } from "wagmi";
import { http } from "viem";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const rpcUrls: Record<number, string> = {
  [mainnet.id]:
    "https://eth-mainnet.g.alchemy.com/v2/wp_JkO86lXk46k7c20njkmzHWIcz3QmL",
  [bsc.id]: "https://binance.llamarpc.com",
  [base.id]:
    "https://base-mainnet.g.alchemy.com/v2/Uw5Fn33W_FuXw_LAP2-Cy_F5C9law7sh",
  [arbitrum.id]:
    "https://arb-mainnet.g.alchemy.com/v2/vrULZ5WB86nelgEEkfDEGeuKYWb4q661",
  [polygon.id]:
    "https://polygon-mainnet.g.alchemy.com/v2/BNenMR9hsppNc6g78ZP9CJBJUpA44Z1o",
};

const projectId =
  process.env["NEXT_PUBLIC_WC_PROJECT_ID"] ||
  "dd5bf4c8f974b01f74f3d578815b3503";

const config = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(rpcUrls[mainnet.id]),
    [bsc.id]: http(rpcUrls[bsc.id]),
    [base.id]: http(rpcUrls[base.id]),
    [arbitrum.id]: http(rpcUrls[arbitrum.id]),
    [polygon.id]: http(rpcUrls[polygon.id]),
  },
});

interface RainbowKitProviderProps {
  children: ReactNode;
}

const queryClient = new QueryClient();
export default function RainbowKit({ children }: RainbowKitProviderProps) {
  return (
    <WagmiConfig config={config}>
      <QueryClientProvider client={queryClient}>
      <RainbowKitProvider modalSize="compact" theme={darkTheme()}>
        {children}
      </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}
