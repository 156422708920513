import { configureStore } from "@reduxjs/toolkit";
import { oneInchApi } from "@/app/services/1inch";
import { persistedSwapReducer, swapReducer } from "@/app/features/swap";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { tokensApi } from "@/app/services/tokens";
import { toasterReducer } from "@/app/features/toaster";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, persistedSwapReducer);

export const store = configureStore({
  reducer: {
    [oneInchApi.reducerPath]: oneInchApi.reducer,
    [tokensApi.reducerPath]: tokensApi.reducer,
    swap: swapReducer,
    swapPersisted: persistedReducer,
    toaster: toasterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(oneInchApi.middleware)
      .concat(tokensApi.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
